html {
    background-color: #eaf5ff;
    overflow-y:scroll;
}

#root {
    background-color: #eaf5ff;
}


body {
    margin: 0;
    font-family: 'LexendDecaRegular';
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

pre {
    background: #f4f4f4;
    border: 1px solid #ddd;
    border-left: 3px solid #4375a5;
    color: #666;
    page-break-inside: avoid;
    font-family: monospace;
    font-size: 15px;
    line-height: 1.6;
    margin-bottom: 1.6em;
    max-width: 100%;
    overflow: auto;
    padding: 1em 1.5em;
    display: block;
    word-wrap: break-word;
}