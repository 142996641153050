/* Neutral 10 */
.remotive-neutral-10-color {
    color: #ffffff !important;
}

.remotive-neutral-10-background {
    background-color: #ffffff !important;
}

.remotive-neutral-10-border {
    border-color: #ffffff !important;
}

/* Neutral 20 */
.remotive-neutral-20-color {
    color: #f9fafb !important;
}

.remotive-neutral-20-background {
    background-color: #f9fafb !important;
}

.remotive-neutral-20-border {
    border-color: #f9fafb !important;
}

/* Neutral 30 */
.remotive-neutral-30-color {
    color: #edf0f3 !important;
}

.remotive-neutral-30-background {
    background-color: #edf0f3 !important;
}

.remotive-neutral-30-border {
    border-color: #edf0f3 !important;
}

/* Neutral 40 */
.remotive-neutral-40-color {
    color: #dde1e6 !important;
}

.remotive-neutral-40-background {
    background-color: #dde1e6 !important;
}

.remotive-neutral-40-border {
    border-color: #dde1e6 !important;
}

/* Neutral 50 */
.remotive-neutral-50-color {
    color: #d0d4d9 !important;
}

.remotive-neutral-50-background {
    background-color: #d0d4d9 !important;
}

.remotive-neutral-50-border {
    border-color: #d0d4d9 !important;
}

/* Neutral 60 */
.remotive-neutral-60-color {
    color: #aaafb6 !important;
}

.remotive-neutral-60-background {
    background-color: #aaafb6 !important;
}

.remotive-neutral-60-border {
    border-color: #aaafb6 !important;
}

/* Neutral 70 */
.remotive-neutral-70-color {
    color: #838d95 !important;
}

.remotive-neutral-70-background {
    background-color: #838d95 !important;
}

.remotive-neutral-70-border {
    border-color: #838d95 !important;
}

/* Neutral 80 */
.remotive-neutral-80-color {
    color: #636b75 !important;
}

.remotive-neutral-80-background {
    background-color: #636b75 !important;
}

.remotive-neutral-80-border {
    border-color: #636b75 !important;
}

/* Neutral 90 */
.remotive-neutral-90-color {
    color: #3f4247 !important;
}

.remotive-neutral-90-background {
    background-color: #3f4247 !important;
}

.remotive-neutral-90-border {
    border-color: #3f4247 !important;
}

/* Neutral 100 */
.remotive-neutral-100-color {
    color: #0e0f11 !important;
}

.remotive-neutral-100-background {
    background-color: #0e0f11 !important;
}

.remotive-neutral-100-border {
    border-color: #0e0f11 !important;
}
