/* Success 10 */
.remotive-success-10-color {
    color: #e5fae7 !important;
}

.remotive-success-10-background {
    background-color: #e5fae7 !important;
}

.remotive-success-10-border {
    border-color: #e5fae7 !important;
}

/* Success 20 */
.remotive-success-20-color {
    color: #d9f9dc !important;
}

.remotive-success-20-background {
    background-color: #d9f9dc !important;
}

.remotive-success-20-border {
    border-color: #d9f9dc !important;
}

/* Success 30 */
.remotive-success-30-color {
    color: #b6f0c3 !important;
}

.remotive-success-30-background {
    background-color: #b6f0c3 !important;
}

.remotive-success-30-border {
    border-color: #b6f0c3 !important;
}

/* Success 40 */
.remotive-success-40-color {
    color: #88ea9e !important;
}

.remotive-success-40-background {
    background-color: #88ea9e !important;
}

.remotive-success-40-border {
    border-color: #88ea9e !important;
}

/* Success 50 */
.remotive-success-50-color {
    color: #59d48a !important;
}

.remotive-success-50-background {
    background-color: #59d48a !important;
}

.remotive-success-50-border {
    border-color: #59d48a !important;
}

/* Success 60 */
.remotive-success-60-color {
    color: #1ac472 !important;
}

.remotive-success-60-background {
    background-color: #1ac472 !important;
}

.remotive-success-60-border {
    border-color: #1ac472 !important;
}

/* Success 70 */
.remotive-success-70-color {
    color: #16a468 !important;
}

.remotive-success-70-background {
    background-color: #16a468 !important;
}

.remotive-success-70-border {
    border-color: #16a468 !important;
}

/* Success 80 */
.remotive-success-80-color {
    color: #0b8551 !important;
}

.remotive-success-80-background {
    background-color: #0b8551 !important;
}

.remotive-success-80-border {
    border-color: #0b8551 !important;
}

/* Success 90 */
.remotive-success-90-color {
    color: #005e3c !important;
}

.remotive-success-90-background {
    background-color: #005e3c !important;
}

.remotive-success-90-border {
    border-color: #005e3c !important;
}

/* Success 100 */
.remotive-success-100-color {
    color: #044132 !important;
}

.remotive-success-100-background {
    background-color: #044132 !important;
}

.remotive-success-100-border {
    border-color: #044132 !important;
}
