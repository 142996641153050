@font-face {
    font-family: 'LexendDecaRegular';
    src: local('LexendDecaRegular'), url(../fonts/LexendDeca-Regular.ttf) format('truetype');
}

@font-face {
    font-family: 'LexendDecaLight';
    src: local('LexendDecaLight'), url(../fonts/LexendDeca-Light.ttf) format('truetype');
}

@font-face {
    font-family: 'LexendDecaBold';
    src: local('LexendDecaBold'), url(../fonts/LexendDeca-Bold.ttf) format('truetype');
}

.lexend-light {
    font-family: 'LexendDecaLight' !important;
}

.lexend-regular {
    font-family: 'LexendDecaRegular' !important;
}

.lexend-bold {
    font-family: 'LexendDecaBold' !important;
}
