/* Local CSS */
@import './neutral-colors.css';
@import './success-colors.css';
@import './primary-colors.css';
@import './warning-colors.css';
@import './fonts.css';
@import './button.css';

/* Styling for feedback plugin */
.feedback-btn-wrapper {
    opacity: 0.95 !important;
    margin-bottom: 1rem !important;
    margin-right: 1rem !important;
}

.popover .popover-arrow::before, .popover .popover-arrow::after {
    border-top-color: #fcfcff !important;
}


.feedback-wrapper {
    opacity: 0.95 !important;
    margin-bottom: 1rem !important;
    margin-right: 1rem !important;
}

.feedback-main {
    font-family: 'LexendDecaRegular' !important;
}

#feedback-btn {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
}

#feedback-submit{
    border-radius: 50px !important;
    padding-left: 20px !important;
    padding-right: 20px !important;
    padding-top: 7px !important;
    padding-bottom: 7px !important;

    background-color: #16a468 !important;
    color: #fff !important;
    border-color: #16a468 !important;
    border-width: 2px !important;   
}

#feedback-loading{
    border-radius: 50px !important;
    padding-left: 20px !important;
    padding-right: 20px !important;
    padding-top: 7px !important;
    padding-bottom: 7px !important;

    background-color: #16a468 !important;
    color: #fff !important;
    border-color: #16a468 !important;
    border-width: 2px !important;   
}

#feedback-back{
    border-radius: 50px !important;
    padding-left: 20px !important;
    padding-right: 20px !important;
    padding-top: 7px !important;
    padding-bottom: 7px !important;

    background-color: #05315a !important;
    color: #fff !important;
    border-color: #05315a !important;
    border-width: 2px !important;
}

#feedback-message {
    font-family: 'LexendDecaRegular' !important;
    font-size: 14px;
}

#feedback-email {
    font-family: 'LexendDecaRegular' !important;
    font-size: 12px;
    padding-top: 4px;
    padding-bottom: 4px;
}

#feedback-back {
    font-family: 'LexendDecaRegular' !important;
}

#feedback-submit {
    font-family: 'LexendDecaRegular' !important;
}

#feedback-btn span {
    font-size: 12px !important;
    font-family: 'LexendDecaRegular' !important;
}

/* App wide CSS */
.App {
    text-align: center;
}

.opacity-85{
    opacity: 0.85 !important;
}

.element-hide {
    display: none !important;
    visibility: hidden !important;
}

.element-show {
    display: block !important;
    visibility: visible !important;
}

.rounded-top-1 {
    border-top-left-radius: 0.33rem;
    border-top-right-radius: 0.33rem;
}
.rounded-bottom-2 {
    border-bottom-right-radius: 0.66rem;
    border-bottom-left-radius: 0.66rem;
}

/* Color used in AddPanelModal panel type selector */
.btn-group label:hover {
    background-color: #7ca1c5 !important;
    color: #fff !important;
}

.btn-check:checked + .btn {
    background-color: #05315a !important;
    color: #fff !important;
}

/* Color used in MainCard tabs */
.main-card-header {
    background-color: transparent !important;
}

.card-header-tabs .nav-link {
    background-color: transparent;
    color: #0b3f70 !important;
}

.card-header-tabs .nav-link.active {
    background-color: #fcfcff !important;
    color: #0b3f70 !important;
}

/* Global inputs (radios, checkboxes etc) */
.form-check-input:checked {
    background-color: #0b3f70 !important;
    border-color: #0b3f70 !important;
}

.form-check-input:checked {
    background-color: #0b3f70 !important;
    border-color: #0b3f70 !important;
}

.radio:checked {
    background-color: #0b3f70 !important;
    border-color: #0b3f70 !important;
}

/* Global font sizes */
.remotive-smallest {
    font-size: 8px !important;
}

.remotive-smaller {
    font-size: 10px !important;
}

.remotive-small {
    font-size: 12px !important;
}

/* NEW Global font sizes */

.remotive-font-xxs {
    font-size: 8px !important;
}

.remotive-font-xs {
    font-size: 10px !important;
}

.remotive-font-sm {
    font-size: 12px !important;
}

.remotive-font-md {
    font-size: 14px !important;
}

.remotive-font {
    font-size: 16px !important;
}

.remotive-font-lg {
    font-size: 18px !important;
}

.remotive-font-xl {
    font-size: 20px !important;
}

.remotive-font-xxl {
    font-size: 24px !important;
}

/* Fade animation */
@-webkit-keyframes fadeIn {
    0% {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }

    50% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

.fade-in {
    opacity: 0;
    -webkit-animation: fadeIn ease-in 1;
    animation: fadeIn ease-in 1;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    -webkit-animation-duration: 750ms;
    animation-duration: 750ms;
}

/* Blink animation */
.blink {
    animation: blink 2.5s steps(1, end) infinite;
}

@keyframes blink {
    0% {
        opacity: 1;
    }

    50% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}


/* ********************** Custom bootstrap ********************** */

/* Hide the blue outline shadow and underline when an accordion is expanded */
.accordion-button:focus {
    box-shadow: none !important;
    border-color: rgba(0,0,0,.125) !important;
}

.accordion-button {
    box-shadow: none !important;
    border-color: rgba(0,0,0,.125) !important;
}

/* Hide the blue background of the accordion exapnd/collapse button that spans across the entire accordion */
.accordion-item{
    background-color: transparent !important;
}

/* Remove the font color change that is applied to the header when an accordion is expanded */
.accordion-button:not(.collapsed){
    color: black !important;
}

.accordion-button{
    background-color: transparent !important;
    padding-left: 0px !important;
    padding-right: 0px !important;
    padding-bottom: 8px !important;
    padding-top: 8px !important;
}

.modal-open{
    padding-right: 0px !important;
}

.z-index-max-background{
    z-index: 10000;
}




.mapContainer {
    height: calc(100vh - 80px);
    display: flex;
    flex-direction: column;
    width: 100%;
    box-sizing: border-box;
    flex-shrink: 0;
    position: static;
    color: #fff;
    /* margin-top: 20px; */
  }
  .mapContainer > div {
    margin: 20px 20px;
  }

/* Copy icon */
.remotive-btn-copy {
    color: #aaafb6;
    position: relative;
}

.remotive-btn-copy::after {
    content: "Click to copy";

}

.remotive-btn-copy:hover {
    color: #616468;
    cursor: pointer;
}

.ms-1_5 {
    margin-left: 0.4rem !important;
}


.remotive-dropdown-dark {
    background-color: #000000 !important;
    opacity: 0.85 !important;
}

.remotive-dropdown-dark > a {
    color: #fff !important;
    opacity: 1 !important;
}

.remotive-dropdown-dark > button:hover {
    color: #000 !important;
    opacity: 1 !important;
}

.remotive-dropdown-dark > a:hover {
    color: #000 !important;
    background-color: #a9a9a9 !important;
    opacity: 1 !important;
}
