/* Warning 10 */
.remotive-warning-10-color {
    color: #fff9e5 !important;
}

.remotive-warning-10-background {
    background-color: #fff9e5 !important;
}

.remotive-warning-10-border {
    border-color: #fff9e5 !important;
}

/* Warning 20 */
.remotive-warning-20-color {
    color: #fff5d7 !important;
}

.remotive-warning-20-background {
    background-color: #fff5d7 !important;
}

.remotive-warning-20-border {
    border-color: #fff5d7 !important;
}

/* Warning 30 */
.remotive-warning-30-color {
    color: #ffedb4 !important;
}

.remotive-warning-30-background {
    background-color: #ffedb4 !important;
}

.remotive-warning-30-border {
    border-color: #ffedb4 !important;
}

/* Warning 40 */
.remotive-warning-40-color {
    color: #ffe176 !important;
}

.remotive-warning-40-background {
    background-color: #ffe176 !important;
}

.remotive-warning-40-border {
    border-color: #ffe176 !important;
}

/* Warning 50 */
.remotive-warning-50-color {
    color: #ffd642 !important;
}

.remotive-warning-50-background {
    background-color: #ffd642 !important;
}

.remotive-warning-50-border {
    border-color: #ffd642 !important;
}

/* Warning 60 */
.remotive-warning-60-color {
    color: #f2bd00 !important;
}

.remotive-warning-60-background {
    background-color: #f2bd00 !important;
}

.remotive-warning-60-border {
    border-color: #f2bd00 !important;
}

/* Success 70 */
.remotive-warning-70-color {
    color: #e7a600 !important;
}

.remotive-warning-70-background {
    background-color: #e7a600 !important;
}

.remotive-warning-70-border {
    border-color: #e7a600 !important;
}

/* Warning 80 */
.remotive-warning-80-color {
    color: #e78b00 !important;
}

.remotive-warning-80-background {
    background-color: #e78b00 !important;
}

.remotive-warning-80-border {
    border-color: #e78b00 !important;
}

/* Warning 90 */
.remotive-warning-90-color {
    color: #e76100 !important;
}

.remotive-warning-90-background {
    background-color: #e76100 !important;
}

.remotive-warning-90-border {
    border-color: #e76100 !important;
}

/* Warning 100 */
.remotive-warning-100-color {
    color: #e83a03 !important;
}

.remotive-warning-100-background {
    background-color: #e83a03 !important;
}

.remotive-warning-100-border {
    border-color: #e83a03 !important;
}

/* Warning 100 with some oppacity */
.remotive-warning-100-transparent-color {
    color: #ffd0ca !important;
}

.remotive-warning-100-transparent-border {
    border-color:#ffd0ca !important;
}

.remotive-warning-100-transparent-background {
    background-color: #ffd0ca !important;
}


