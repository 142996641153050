/* Success */
.remotive-btn-success {
    background-color: #16a468;
    color: #fff;
    border-color: #16a468;
    border-width: 2px;
}

.remotive-btn-success:hover {
    background-color: #044132;
    color: #fff;
    border-color: #044132;
    border-width: 2px;
}

.remotive-btn-success:active {
    background-color: #fff !important;
    color: #16a468 !important;
    border-color: #fff !important;
    border-width: 2px !important;
}

.remotive-btn-success:focus {
    outline-style: solid;
    outline-color: #05315a50;
    outline-width: 3px;
    background-color: #16a468;
    color: #fff;
    border-color: #16a468;
    border-width: 2px;
}

.remotive-btn-success:disabled {
    background-color: #b4b3b3 !important;
    color: #fff !important;
    border-color: #b4b3b3 !important;
    border-width: 2px !important;
    cursor: not-allowed !important;
    pointer-events: all !important;
}

/* Primary */
.remotive-btn-primary {
    background-color: #05315a;
    color: #fff;
    border-color: #05315a;
    border-width: 2px;
}

.remotive-btn-primary:hover {
    background-color: #4375a5;
    color: #fff;
    border-color: #4375a5;
    border-width: 2px;
}

.remotive-btn-primary:active {
    background-color: #fff !important;
    color: #05315a !important;
    border-color: #fff !important;
    border-width: 2px !important;
}

.remotive-btn-primary:focus {
    outline-style: solid;
    outline-color: #05315a50;
    outline-width: 3px;
    background-color: #05315a;
    color: #fff;
    border-color: #05315a;
    border-width: 2px;
}

.remotive-btn-primary:disabled {
    background-color: #b4b3b3 !important;
    color: #fff !important;
    border-color: #b4b3b3 !important;
    border-width: 2px !important;
    cursor: not-allowed !important;
    pointer-events: all !important;
}

/* Primary dark */
.remotive-btn-primary-dark {
    background-color: #00182e !important;
    color: #fff !important;
    border-color: #00182e !important;
    border-width: 2px !important;
}

.remotive-btn-primary-dark:hover {
    background-color: #0b3f70 !important;
    color: #fff !important;
    border-color: #0b3f70 !important;
    border-width: 2px !important;
}

.remotive-btn-primary-dark:active {
    background-color: #fff !important;
    color: #0b3f70 !important;
    border-color: #fff !important;
    border-width: 2px !important;
}

.remotive-btn-primary-dark:focus {
    outline-style: solid !important;
    outline-color: #4375a550 !important;
    outline-width: 3px !important;
    background-color: #0b3f70 !important;
    color: #fff !important;
    border-color: #0b3f70 !important;
    border-width: 2px !important;
}

.remotive-btn-primary-dark:disabled {
    background-color: #838d95 !important;
    color: #fff !important;
    border-color: #838d95 !important;
    border-width: 2px !important;
    cursor: not-allowed !important;
    pointer-events: all !important;
}



/* Primary without background */
.remotive-btn-no-bg {
    background-color: transparent !important;
    color: #05315a !important;
    border-width: 0px !important;
}

.remotive-btn-no-bg:hover {
    background-color: transparent !important;
    color: #7ca1c5 !important;
    border-width: 0px !important;
}

.remotive-btn-no-bg:active {
    background-color: transparent !important;
    color: #05315a !important;
    border-width: 0px !important;
}

.remotive-btn-no-bg:focus {
    outline-style: solid;
    outline-color: #05315a50;
    outline-width: 3px;
    background-color: transparent;
    color: #05315a;
    border-width: 0px;
}

.remotive-btn-no-bg:disabled {
    background-color: transparent !important;
    color: #aaafb6 !important;
    border-color: #aaafb6 !important;
    border-width: 0px !important;
    cursor: not-allowed !important;
    pointer-events: all !important;
}

/* Secondary */
.remotive-btn-secondary {
    background-color: #fff;
    color: #05315a;
    border-color: #05315a;
    border-width: 2px;
}

.remotive-btn-secondary:hover {
    background-color: #4375a5;
    color: #fff;
    border-color: #4375a5;
    border-width: 2px;
}

.remotive-btn-secondary:active {
    background-color: #fff !important;
    color: #05315a !important;
    border-color: #fff !important;
    border-width: 2px !important;
}

.remotive-btn-secondary:focus {
    outline-style: solid;
    outline-color: #05315a50;
    outline-width: 3px;
    background-color: #fff;
    color: #05315a;
    border-color: #05315a;
    border-width: 2px;
}

.remotive-btn-secondary:disabled {
    background-color: #b4b3b3 !important;
    color: #fff !important;
    border-color: #b4b3b3 !important;
    border-width: 2px !important;
    cursor: not-allowed !important;
    pointer-events: all !important;
}

/* Button sizes */

.remotive-btn {
    border-radius: 50px !important;
    padding-left: 20px !important;
    padding-right: 20px !important;
    padding-top: 7px !important;
    padding-bottom: 7px !important;
}

.remotive-btn-md {
    border-radius: 50px !important;
    padding-left: 15px !important;
    padding-right: 15px !important;
    padding-top: 4px !important;
    padding-bottom: 4px !important;
    font-size: 12px !important;
}

.remotive-btn-sm {
    border-radius: 50px !important;
    padding-left: 11px !important;
    padding-right: 11px !important;
    padding-top: 2px !important;
    padding-bottom: 2px !important;
    margin-top: 5px !important;
    margin-bottom: 5px !important;
    margin-right: 2px !important;
    margin-left: 2px !important;
    font-size: 12px !important;
}
